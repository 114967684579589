import { myAxios } from '@/utils/request'

// 分页获取朋友圈待发送列表
const ApiGetWaitPushFriendCircleFollowMsgPage = (data = {}) => {
  return myAxios.post('/UserFriendCircle/GetWaitPushFriendCircleFollowMsgPage', {
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    // 排序字段名称
    sortName: data.sortName,
    sortOrder: 0,
    // 机器人编号
    robotWxId: data.robotWxId
  })
}
// 待发送列表官方朋友圈设置跟发状态和用户朋友圈删除状态
const ApiWaitPushRobotFollowSet = (data = {}) => {
  return myAxios.post('/UserFriendCircle/WaitPushRobotFollowSet', {
    // 机器人编号
    robotWxId: data.robotWxId,
    // 官方朋友圈ID（只能设置跟发状态）
    tId: data.tId,
    // 用户朋友圈ID（只能删除）
    ucId: data.ucId,
    // 状态（true跟发、false不跟发、false删除）
    state: data.state
  })
}
// 分页获取朋友圈已发送列表
const ApiSentPushFriendMsgPage = (data = {}) => {
  return myAxios.post('/UserFriendCircle/SentPushFriendMsgPage', {
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    // 排序字段名称
    sortName: data.sortName,
    sortOrder: 0,
    // 机器人编号
    robotWxId: data.robotWxId
  })
}

// 已发送列表删除
const ApiSentPushFriendMsgDel = (rId = '') => {
  return myAxios.post('/UserFriendCircle/SentPushFriendMsgDel', {
    // 记录ID
    rId: rId
  })
}
// 用户自动跟发朋友圈开关设置
const ApiUserFriendCircleFollowSet = (followState) => {
  return myAxios.post('/UserFriendCircle/UserFriendCircleFollowSet', {
    // 跟发状态（true跟发、false不跟发）
    followState: followState
  })
}

// 设置朋友圈可见
const ApiUserFriendCircleFollowPrivateSet = (data = {}) => {
  return myAxios.post('/UserFriendCircle/UserFriendCircleFollowPrivateSet', {
    // 跟发状态（true跟发、false不跟发）
    privateType: data.privateType,
    privateTageIds: data.privateTageIds
  })
}

// 获取用户跟发设置
const ApiGetUserFollowSet = () => {
  return myAxios.get('/UserFriendCircle/GetUserFollowSet')
}
// 获取机器人标签【异步调用】
const ApiGetAllTags = (robotWxId = '') => {
  return myAxios.get('/UserFriendCircle/GetAllTags', {
    params: {
      robotWxId: robotWxId
    }
  })
}

// 获取机器人标签及标签下的好友
const ApiGetTagInFriends = (robotWxId = '') => {
  return myAxios.get('/UserFriendCircle/GetTagInFriends', {
    params: {
      robotWxId: robotWxId
    }
  })
}

export {
  // 分页获取朋友圈待发送列表
  ApiGetWaitPushFriendCircleFollowMsgPage,
  // 待发送列表官方朋友圈设置跟发状态和用户朋友圈删除状态
  ApiWaitPushRobotFollowSet,
  // 分页获取朋友圈已发送列表
  ApiSentPushFriendMsgPage,
  // 已发送列表删除
  ApiSentPushFriendMsgDel,
  // 用户自动跟发朋友圈开关设置
  ApiUserFriendCircleFollowSet,
  // 设置朋友圈可见
  ApiUserFriendCircleFollowPrivateSet,
  // 获取用户跟发设置
  ApiGetUserFollowSet,
  // 获取机器人标签【异步调用】
  ApiGetAllTags,
  // 获取机器人标签及标签下的好友
  ApiGetTagInFriends
}
