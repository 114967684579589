<template>
  <div>
    <!--文字-->
    <div v-if="data.msgType===2001" class="text"><span v-if="data.isAtNewcomer" class="at"><span>@新人昵称</span></span>{{ data.msgContent }}</div>
    <!--图片-->
    <img v-if="data.msgType===2002" class="img" :src="data.msgContent" @click="previewImage">
    <!--链接-->
    <div v-if="data.msgType===2005" class="link">
      <div class="b1">{{ data.msgTitle || data.title }}</div>
      <div class="b2 flex">
        <div class="flex-1" style="overflow: hidden">
          <div class="p1">{{ data.msgDes || data.desc }}</div>
        </div>
        <div>
          <img :src="data.msgContent" mode="aspectFill" class="img">
        </div>
      </div>
    </div>
    <!--文件-->
    <div v-if="data.msgType===2010" class="file">
      <div>
        <img v-if="data.msgType===2010 " :src="data.suffixName || filterImg(data.suffixName)" mode="aspectFill" class="link-img">
      </div>
      <div class="flex-1">
        <div class="p1">{{ data.title ? data.title : data.msgTitle }}</div>
        <div v-if="data.msgDes || data.desc" class="p2">{{ data.desc ? data.desc : data.msgDes }}</div>
      </div>
    </div>
    <!--小程序-->
    <div v-if="data.msgType===2013 || data.msgType===2015" class="mini-program">
      <div class="mp-p1 flex">
        <div v-if="data.logo"><img :src="data.logo" style="display: block;width: .18rem;height: .18rem;border-radius: 50%;margin-right: .05rem;"></div>
        <div>{{ data.title ? data.title : data.msgTitle }}</div>
      </div>
      <div v-if="data.msgDes || data.desc" class="mp-p0">{{ data.desc ? data.desc : data.msgDes }}</div>
      <div class="mp-p2">
        <img :src="data.href ? data.href : data.msgHref" mode="aspectFill" class="mp-p2-1">
      </div>
      <div class="mp-p3">
        <div class="mp-p3-1" />
        <div>{{ data.msgType===2013 ? '小程序' : '公众号' }}</div>
      </div>
    </div>
    <!--名片-->
    <div v-if="data.msgType===2006" class="card">
      <div class="b1 flex">
        <div><img :src="data.msgContent" mode="aspectFill" class="img"></div>
        <div class="flex-1">
          <div class="p1">{{ data.msgTitle }}</div>
        </div>
      </div>
      <div class="b2">个人名片</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          isAtNewcomer: 0,	// at
          msgGroupNum: 0,	// integer($int32) 消息组号
          msgSort: 0, //	integer($int32) 消息排序数字（小到大升序）
          msgType: 2001, //	integer($int32) 消息类型（ 2001 文字 、2002 图片、 2003 语音(只支持amr格式)、 2004 视频 、2005 链接、 2006 好友名片、 2010 文件 、2013 小程序、 2016 音乐、2007动画表情）
          msgContent: '', //	string  图片、文件、音频、链接图片URL、内容
          msgHref: '', //	string 视频或者链接URL
          msgTitle: '',	// string 文件或者链接标题
          msgDes: '',	// string 链接描述
          msgDurationTime: 0//	integer($int32) 音频或者视频时长（单位：秒）
        }
      }
    }
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    previewImage() {
      this.$ImagePreview({
        images: [this.data.msgContent]
      })
    },
    filterImg(name) {
      const type = name.split('.')[name.length - 1]
      if (type === 'ppt') {
        return '../../static/imgs/type/ppt.png'
      } else if (type === 'xls') {
        return '../../static/imgs/type/xls.png'
      } else {
        return '../../static/imgs/type/doc.png'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text{
  white-space: pre-line;
  line-height: px(24);
  .at{
    display: inline-block;
    margin-right: px(5);
    padding: 0 px(5);
    height: px(24);
    line-height: px(24);
    background: rgba($color-primary, 0.15);
    border-radius: 2px;
    vertical-align: middle;
    color: $color-primary;
    font-size: px(12);
    span{
      display: block;
      transform: scale(.833);
    }
  }
}
.img{
  display: block;
  width: px(60);
  height: px(60);
  border-radius: px(2);
  background: #f6f6f6;
}
.mini-program{
  width: px(203);
  background: #F6F6F6;
  border-radius: px(4);
  .mp-p0{
    margin-top: px(-5);
    padding: 0 px(10) px(10);
    color: #999;
    line-height: 1.4;
  }
  .mp-p1{
    padding: px(10);
    line-height: 1.4;
  }
  .mp-p2{
    padding: 0 px(10) px(10);
  }
  .mp-p2-1{
    display: block;
    width: 100%;
    height: 109px;
    border-radius: px(4);
    object-fit: cover;
  }
  .mp-p3{
    display: flex;
    align-items: center;
    border-top: solid px(1) #E7E7E7;
    padding: px(5) px(10);
    color: #999;
    font-size: px(10);
  }
  .mp-p3-1{
    margin-right: px(5);
    width: px(10);
    height: px(10);
    background: url("assets/icon-2.png") center no-repeat;
    background-size: contain;
  }
}
.file{
  display: flex;
  align-items: center;
  overflow: hidden;
  .link-img{
    margin-right: px(10);
    display: block;
    width: px(44);
    height: px(44);
    border-radius: px(2);
  }
  .flex-1{
    flex: 1;
    overflow: hidden;
  }
  .p1{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: px(14);
  }
  .p2{
    margin-top: px(2);
    color: #999;
    font-size: px(12);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.link{
  padding: px(10) px(10) px(15);
  max-width: px(240);
  background: #f6f6f6;
  border-radius: px(4);
  box-sizing: border-box;
  .b1{
    font-size: px(14);
    font-weight: bold;
    max-height: px(40);
    line-height: px(20);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .b2{
    margin-top: px(5);
    .p1{
      height: px(34);
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: px(17);
      color: #999;
      font-size: px(12);
    }
    .img{
      margin-left: px(10);
      display: block;
      width: px(48);
      height: px(48);
      border-radius: px(2);
    }
  }
}
.card{
  padding: 0 px(10);
  max-width: px(215);
  background: #F6F6F6;
  border-radius: px(4);
  .b1{
    padding: px(10) 0;
    .img{
      margin: 0 px(10) 0 px(2);
      display: block;
      width: px(40);
      height: px(40);
      border-radius: 50%;
    }
    .p1{
      font-weight: bold;
      line-height: 1.6;
      font-size: px(14);
    }
  }
  .b2{
    border-top: solid px(1) #E7E7E7;
    padding: px(5) 0;
    font-size: px(10);
    color: #999;
  }
}
</style>
